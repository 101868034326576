import { render, staticRenderFns } from "./printHistoriaJuventud.vue?vue&type=template&id=c80f239c&scoped=true"
import script from "./printHistoriaJuventud.vue?vue&type=script&lang=js"
export * from "./printHistoriaJuventud.vue?vue&type=script&lang=js"
import style0 from "./printHistoriaJuventud.vue?vue&type=style&index=0&id=c80f239c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c80f239c",
  null
  
)

export default component.exports